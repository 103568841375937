//Author June Leow
//Date Jul 25th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import FileSaver from 'file-saver';
import React, {useReducer, useEffect} from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

//load token and check if it's expired
let session;
let moment = require('moment-timezone');

//refresh 5 minutes before expire
let now = moment().add(5,'minutes').tz('America/Los_Angeles').unix();
let tokenExpire = localStorage.getItem('expire');

if(tokenExpire<now){
  session = {};
}
else{

  session = {
    token:localStorage.getItem('token'),
    first_name:localStorage.getItem('first_name'),
    last_name:localStorage.getItem('last_name'),
    email:localStorage.getItem('email'),
    role:localStorage.getItem('role'),
    expire:localStorage.getItem('expire'),
  };
}

//initialize the state
const initialState = {
  referenceNum:'',
  email:'',
  password:'',

  session:session,

  shippingFiles:[],
  appraisal:{},
  entities:[]
};

//reducer function that perform state update
const reducer = getReducer();


const ShippingPortal  = (props)=>{
  const controller = new AbortController();

  let referenceNum = useParams().referenceNum;
  let email = useParams().email;
  let password = useParams().password;
  let newInitialState = Object.assign({}, initialState, {
    referenceNum:referenceNum?referenceNum:'',
    email:email?email:'',
    password:password?password:'',
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPost2 = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    if(Object.keys(state.session).length > 0){
      //get appraisal information
      getAppraisal();

      //get appraisal file
      getShippingFiles();
    }

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const logout=()=>{
    localStorage.setItem('token', '');
    localStorage.setItem('first_name', '');
    localStorage.setItem('last_name', '');
    localStorage.setItem('email', '');
    localStorage.setItem('role', '');
    localStorage.setItem('expire', '');
    localStorage.setItem('appraisal_fk','');

    setState({session:{}});
  }
  
  //API call
  const downloadAppraisalCover=()=>{
    let id = localStorage.getItem('appraisal_fk');
    let parameters = [
      {
        field:'appraisalFk',
        value:id
      }
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'appraisal_cover.pdf');
    };
    
    httpPost('file/appraisal/cover/download', parameters, '', 'Oops, something went wrong and could not load the appraisal cover. Please try again later.', callBack);
  }

  const getShippingFiles=()=>{
    let id = localStorage.getItem('appraisal_fk');
    const callBack = apiCallBack([{state:'shippingFiles', key:'data'}]);
    httpGet('shipping/file/'+id,'','Oops, something went wrong and could not load appraisal files. Please try again later.', callBack);
  }

  const onSubmit=(e)=>{
    e.preventDefault();

    let parameters = [
      {
        field:'referenceNum',
        value:state.referenceNum
      },
      {
        field:'password',
        value:state.password
      },
      {
        field:'email',
        value:state.email
      }
    ];


    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        //set session
        let payload = response.data;
        console.log(payload)
        localStorage.setItem('token', payload.token);
        localStorage.setItem('first_name', payload.first_name);
        localStorage.setItem('last_name', payload.last_name);

        localStorage.setItem('email', payload.email);
        localStorage.setItem('role', payload.role);
        localStorage.setItem('expire', payload.expire);
        localStorage.setItem('appraisal_fk',payload.appraisal_fk);

        let session = {
          token :payload.token,
          first_name :payload.first_name,
          last_name :payload.last_name,
          email :payload.email,
          role :payload.role,
          expire :payload.expire,
        }
        setState({session:session});
        getAppraisal();
        getShippingFiles();
      }
    };
    httpPost2('shipping/login', parameters, 'Login successfully.', 'Login failed, please make sure you email, password & reference number combination is correct.', callBack);
  }

  const getAppraisal=()=>{
    let id = localStorage.getItem('appraisal_fk');
    
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        if(response.data.data.reference_num!==state.referenceNum){
          logout();
        }
        else{
          setState({appraisal:response.data.data});

          let entities = [];
          for(let i=0;i<response.data.data.entities.length;i++){
            let entity = response.data.data.entities[i];
            entities.push(entity);
          }
          setState({entities:entities});
        }
      }
    };

    httpGet('shipping/appraisal/'+id,'','Oops, something went wrong and could not load this appraisal. Please try again later.', callBack)
  }

  const downloadFile=(name)=>{

    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let url = response.data.url;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      }
    };


    let parameters = [
      {
        field:'parentFk',
        value:localStorage.getItem('appraisal_fk')
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'Orders'
      }
    ];

    httpPost('shipping/file/presign/download', parameters, '', 'Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  //render
  if(Object.keys(state.session).length > 0){
    let userName;

    if(state.session && state.session.first_name!=='' && state.session.last_name!==''){
      userName = <div className="display-inline" style={{lineHeight:'50px'}}>
        <i className="fa fa-user topbar-icon"></i> {state.session.first_name+' '+state.session.last_name}
      </div>;
    }

    let appraisalCover;

    if(state.appraisal.client_fk==='477'){
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>Appraisal Cover</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Appraisal_Cover.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    else if(state.appraisal.client_fk==='2776'){
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>Borrower Disclosure</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Borrower_Disclosure.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    else if(state.appraisal.client_fk==='1631'||state.appraisal.client_fk==='217'||state.appraisal.client_fk==='242'||state.appraisal.client_fk==='140'||state.appraisal.client_fk==='159'){
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>ROV Disclosure</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Borrower_Disclosure.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    else if(state.appraisal.client_fk==='194'){
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>ROV & Delivery Disclosure</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Borrower_Disclosure.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    else if(state.appraisal.client_fk==='2162'){
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>Borrower Disclosure</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Borrower_Disclosure.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    else{
      appraisalCover = <div>
        <div className="file-container">
            <div className="font-bold">
            <b>Borrower Disclosure</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadAppraisalCover()}><b>{state.appraisal.reference_num+'_Borrower_Disclosure.pdf'}</b></div>
          </div>
          <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}- System</div></font>
        </div>
        <br/>
      </div>;
    }
    return(
      <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
        <div className="topbar">
            <div className="topbar-logo-container">
              <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
            </div>
            <div className="topbar-action-panel-container">
              <div className="topbar-action-panel">
                {userName}
                &nbsp;&nbsp;
                <div className="display-inline cursor-pointer" style={{lineHeight:'50px'}} onClick={logout}>
                  <i className="fa fa-unlock topbar-icon"></i> Logout
                </div>
              </div>
              &nbsp;&nbsp;
            </div>
        </div>
        <div className="padding">
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-info"></i> Appraisal Information
            </CardHeader>
            <CardBody className="no-padding">
              <div className="padding" style={{background:'white'}}>
                <Row>
                  <Col sm="5">
                    <Row>
                      <Col sm="12">
                        <b><i className="fa fa-file"></i> Loan information</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Reference #
                      </Col>
                      <Col sm="8">
                        {state.appraisal.reference_num}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Loan #
                      </Col>
                      <Col sm="8">
                        {state.appraisal.loan_num}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Reference #
                      </Col>
                      <Col sm="8">
                        {state.appraisal.appraised_value}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Loan type
                      </Col>
                      <Col sm="8">
                        {state.appraisal.loan_type}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Loan purpose
                      </Col>
                      <Col sm="8">
                        {state.appraisal.loan_purpose}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Appraisal type(s)
                      </Col>
                      <Col sm="8">
                        {state.appraisal.appraisal_type}
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col sm="12">
                        <b><i className="fa fa-map"></i> Property address</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Street
                      </Col>
                      <Col sm="8">
                        {state.appraisal.property_street}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        City
                      </Col>
                      <Col sm="8">
                        {state.appraisal.property_city}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        State
                      </Col>
                      <Col sm="8">
                        {state.appraisal.property_state}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Zip
                      </Col>
                      <Col sm="8">
                        {state.appraisal.property_zip}
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col sm="4">
                        <label><i className="fa fa-bank"></i> Lender</label>
                      </Col>
                      <Col sm="8">
                        {state.appraisal.company}
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col sm="12">
                        <b><i className="fa fa-user"></i> Borrower</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        First name
                      </Col>
                      <Col sm="8">
                        {state.appraisal.borrower_f_name}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Last name
                      </Col>
                      <Col sm="8">
                        {state.appraisal.borrower_l_name}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Email
                      </Col>
                      <Col sm="8">
                        {state.appraisal.borrower_email}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        Phone
                      </Col>
                      <Col sm="8">
                        {state.appraisal.borrower_phone}
                      </Col>
                    </Row>

                    <br/>
                    <Row>
                      <Col sm="4">
                        <label><i className="fa fa-truck"></i>Date shipped</label>
                      </Col>
                      <Col sm="8">
                        {formatDateTime(state.appraisal.datetime_ship)}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="7">
                    <br/>
                    {
                      state.entities.map(
                        (entity,index)=>{
                          return(
                            <Row key={index}>
                              <Col sm="12">
                                <i className="fa fa-user"></i> <b>{entity.entity_label}</b>
                              </Col>
                              <Col sm="12">
                                <Row >
                                  <Col sm="4">
                                    Name
                                  </Col>
                                  <Col sm="8">
                                    {entity.entity_name}
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="12">
                                <Row >
                                  <Col sm="4">
                                    Email / Phone
                                  </Col>
                                  <Col sm="4">
                                    {entity.entity_email}
                                  </Col>
                                  <Col sm="4">
                                    {entity.entity_phone}
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="12">
                                &nbsp;
                              </Col>
                            </Row>
                          );
                        }
                      )
                    }
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-files"></i> Appraisal files
            </CardHeader>
            <CardBody className="no-padding">
              <div className="padding medium-scroll-container" style={{background:'white'}}>
                {appraisalCover}
                {
                  state.shippingFiles.map(
                    (appraisalFile, index)=>{
                      return(
                        <div key={index}>
                          <div className="file-container">
                              <div className="font-bold">
                              <b>{appraisalFile.file_type}</b>
                              <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(appraisalFile.name)}><b>{appraisalFile.name}</b></div>
                            </div>
                            <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{appraisalFile.datetime_created} - {appraisalFile.uploader_name}</div></font>
                          </div>
                          <br/>
                        </div>
                      );
                    }
                  )
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
  else{
    return(
      <div className="login-background" style={{backgroundImage:'url("/img/login_background.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
        <form style={{marginBottom:'0px'}} name="loginForm" onSubmit={onSubmit}>
          <div className="login-container well padding">
            <br/>
            <center>
              <img alt="company logo" src="/../img/logo_small.png" className="logo-login"/><br/>
              Appraisal download portal
            </center>
            <NavLink className="link-color" to="/">Back to homepage</NavLink>
            <div className="non-empty-container">
              <p className="no-padding no-margin"></p>
            </div>
            <div className="form-group" style={{height:'38px'}}>
              <label className="font-blue bold"><b>Email:</b></label>
              <input type="email"  name="username" autoComplete="new-password" value={state.email} onChange={e => setState({email: e.target.value})} className="form-control" id="email"/>
            </div>
            <br/>
            <label className="font-blue bold"><b>Password:</b></label>
            <div className="form-group" style={{height:'38px'}}>
              <input type="password" name="password" autoComplete="new-password" value={state.password} onChange={e => setState({password: e.target.value})} className="form-control" id="password"/>
            </div>
            <br/>
            <div className="form-group" style={{height:'38px'}}>
              <label className="font-blue bold"><b>Reference #:</b></label>
              <input type="text"  name="referenceNum" autoComplete="new-password" value={state.referenceNum} onChange={e => setState({referenceNum: e.target.value})} className="form-control" id="referenceNum"/>
            </div>
            <br/>
            <input type="submit" className="btn btn-blue-noshadow btn-block font-white" value="Sign In"></input>
            <br/>
             <p style={{fontSize:'12px'}}>
              <i>
                <i className="red-color fa fa-lock"/> &nbsp;This portal is secured. Please do not share this link with others for your privacy and security. **<br/>
                <i className="red-color fa fa-edit"/> By logging in to the appraisal delivery portal, you hereby consent to electronic delivery. **
              </i>
             </p>
          </div>
        </form>
      </div>
    );
  }
}


export default ShippingPortal;
