//Author June Leow
//Date Jul 29th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../../util/util';
import {Card, CardHeader, CardBody} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {

};

//reducer function that perform state update
const reducer = getReducer();


const RebuttalSelect  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call

  //render
  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <br/>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Rebuttal request menu
          </CardHeader>
          <CardBody>
            <div className="well">
              <ul>
                <li>
                  <NavLink to="/rebuttal/new-rebuttal">Requesting a new rebuttal</NavLink><br/>
                  <i>Choose this if you want to submit a brand new rebuttal request.</i>
                  <br/><br/>
                </li>
                <li>
                  <NavLink to="/rebuttal/update-rebuttal">Update existing rebuttal request</NavLink><br/>
                  <i>Choose this if you already submitted a rebuttal and providing updates to the existing rebuttal.</i>
                </li>
              </ul>
              <br/><br/>
              <font color="red">*</font>If you have any question regarding matter of rebuttal please email <a href="mailto:reconsideration@homevms.com" target="_top">reconsideration@homevms.com</a> for assiting.
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default RebuttalSelect;
